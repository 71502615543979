import React from 'react';
import { ReactComponent as Ads } from './ads.svg';
import { ReactComponent as Analytics } from './analytics.svg';
import { ReactComponent as HelpCenter } from './help-center.svg';
import { ReactComponent as Moments } from './moments.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Topics } from './topics.svg';

const listModalData = [
	{
		link: 'Find Developers',
		icon: <Moments />,
		href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
		divider: false,
	},
	{
		link: 'Become A Seller',
		icon: <Analytics />,
		href: 'https://www.hostjane.com/sell',
		divider: false,
	},
	{
		link: 'Settings',
		icon: <Settings />,
		href: 'https://www.hostjane.com/marketplace/settings',
		divider: true,
	},
	{
		link: 'Help Center',
		icon: <HelpCenter />,
		href: 'https://help.hostjane.com',
		divider: false,
	},
	{
		link: 'Feedback',
		icon: <Ads />,
		href: 'https://www.hostjane.com/marketplace/contact',
		divider: false,
	},
];

export default listModalData;
