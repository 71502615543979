import classnames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../../svgs/icons/chevron-down.svg';

const DropdownWrapper = styled.div`
	&.hostjane-main__dropdown-button {
		position: relative;
		padding: 0 !important;
		display: block;

		@media (max-width: 768px) {
			display: none;
		}
		.hostjane-main__dropdown-button__btn {
			display: inline-block;
			padding: 0 10px;
			font-weight: 600;
			color: #333;
			font-size: 16px;
			background: transparent;
			width: 75px !important;
			display: flex;
			align-items: flex-end;
			gap: 4px;
			height: 100%;

			svg {
				width: 12px;
				height: 12px;
				margin-bottom: 3px;
			}
		}
		.hostjane-main__dropdown-button__list {
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			background: #fff;
			visibility: hidden;
			height: fit-content !important;
			opacity: 0;
			outline: 0;
			overflow-y: auto;
			overflow-x: clip;
			padding: 8px 0;
			pointer-events: none;
			position: absolute;
			transition: opacity 0.2s, visibility 0.2s;
			z-index: 1015;
			top: 42px;
			left: 0px;
			min-width: 110px;

			li {
				height: fit-content !important;
				display: none;
			}

			&::-webkit-scrollbar {
				height: 8px !important;
				width: 8px;
				cursor: pointer;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				height: 8px !important;
				width: 8px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: rgba(128, 134, 139, 0.26);
				border-radius: 8px;
			}
			&.active {
				visibility: visible;
				opacity: 1;
				pointer-events: auto;
			}
			.hostjane-main__dropdown-button__list__item {
				display: flex;
				margin: 0;
				min-height: 48px;
				min-width: 100%;
				padding: 0 10px;
				white-space: nowrap;
				align-items: center;
				font-weight: 600;
				color: #333;
				font-size: 16px;
				&:hover {
					background-color: #f1f3f4;
					cursor: pointer;
				}
			}

			@media (max-width: 1245px) {
				.item-settings-item {
					display: flex;
				}
			}

			@media (max-width: 1187px) {
				.item-profile-item {
					display: flex;
				}
			}

			@media (max-width: 1095px) {
				.item-resolution-item {
					display: flex;
				}
			}

			@media (max-width: 962px) {
				.item-reporting-item {
					display: flex;
				}
			}
			@media (max-width: 890px) {
				.item-listings-item {
					display: flex;
				}
			}

			@media (max-width: 815px) {
				.item-messages-item {
					display: flex;
				}
			}

			@media (max-width: 587px) {
				.item-order-item {
					display: flex;
				}
			}

			@media (max-width: 535px) {
				.item-dashboard-item,
				.item-hosting-item {
					display: flex;
				}
			}
		}
	}
`;

function useOutsideAlerter(ref, setState, stateValue) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setState(stateValue);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, setState, stateValue]);
}

const Dropdown = () => {
	const [isListActive, setIsListActive] = useState(false);
	const [activeLink, setActiveLink] = useState('I want');
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, setIsListActive, false);

	return (
		<DropdownWrapper
			ref={wrapperRef}
			className="hostjane-main__dropdown-button"
		>
			<button
				type="button"
				className="hostjane-main__dropdown-button__btn"
				onClick={() => setIsListActive(!isListActive)}
			>
				<span>More</span>
				<ChevronDown />
			</button>
			<ul
				className={classnames(
					'hostjane-main__dropdown-button__list',
					isListActive && 'active'
				)}
			>
				<li className="item-dashboard-item">
					<a
						href="https://www.hostjane.com/marketplace"
						className="hostjane-main__dropdown-button__list__item"
					>
						Dashboard
					</a>
				</li>
				<li className="item-hosting-item">
					<a
						href="https://cloud.hostjane.com"
						className="active"
					>
						Hosting
					</a>
				</li>
				<li className="item-order-item">
					<a
						href="https://www.hostjane.com/marketplace/orders"
						className="hostjane-main__dropdown-button__list__item"
					>
						Orders
					</a>
				</li>
				<li className="item-messages-item">
					<a
						href="https://www.hostjane.com/marketplace/messages"
						className="hostjane-main__dropdown-button__list__item"
					>
						Messages
					</a>
				</li>
				<li className="item-listings-item">
					<a
						href="https://www.hostjane.com/marketplace/listings"
						className="hostjane-main__dropdown-button__list__item"
					>
						Listings
					</a>
				</li>
				<li className="item-reporting-item">
					<a
						href="https://www.hostjane.com/marketplace/reporting"
						className="hostjane-main__dropdown-button__list__item"
					>
						Reporting
					</a>
				</li>
				<li className="item-resolution-item">
					<a
						href="https://www.hostjane.com/marketplace/disputes"
						className="hostjane-main__dropdown-button__list__item"
					>
						Resolution
					</a>
				</li>
				<li className="item-profile-item">
					<a
						href="https://www.hostjane.com/marketplace/Swiftmodders"
						className="hostjane-main__dropdown-button__list__item"
					>
						My Profile
					</a>
				</li>
				<li className="item-settings-item">
					<a
						href="https://www.hostjane.com/marketplace/settings"
						className="hostjane-main__dropdown-button__list__item"
					>
						Settings
					</a>
				</li>
			</ul>
		</DropdownWrapper>
	);
};

export default Dropdown;
