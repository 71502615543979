/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { ReactComponent as HostJaneIcon } from '../../svgs/nav/HostJane.svg';
import { ReactComponent as JaneCloudIcon } from '../../svgs/nav/JaneCloud.svg';
import { ReactComponent as JaneDediIcon } from '../../svgs/nav/JaneDedi.svg';
import { ReactComponent as JaneSellsIcon } from '../../svgs/nav/JaneSells.svg';
import { ReactComponent as JaneVPSIcon } from '../../svgs/nav/JaneVPS.svg';
import { ReactComponent as AccountIcon } from '../../svgs/homenav/notification.svg';
import { ReactComponent as CategoriesIcon } from '../../svgs/homenav/hash.svg';
import { ReactComponent as MessagesIcon } from '../../svgs/homenav/message.svg';
import { ReactComponent as SkillsSidebarIcon } from '../../svgs/homenav/skills-sidebar.svg';
import { ReactComponent as WebHostingIcon } from '../../svgs/homenav/web-hosting.svg';
import { ReactComponent as MoreIcon } from '../../svgs/homenav/more.svg';
import { ReactComponent as Wantcpanel } from '../../svgs/homenav/cpanel.svg';

import { ReactComponent as AwsIcon } from '../../svgs/nav/aws.svg';
import { ReactComponent as GoogleIcon } from '../../svgs/nav/google.svg';
import { ReactComponent as OvhIcon } from '../../svgs/nav/ovh.svg';
import { ReactComponent as IbmIcon } from '../../svgs/nav/ibm.svg';
import ListModal from './ListModal';

const Nav = styled.nav`
	position: fixed;
	z-index: 9999;
	bottom: 0;
	width: 100%;
	height: 64px;

	background: ${props => props.theme.background};
	box-shadow: 2px -1px 2px 0 ${props => props.theme.neutrals.lightOne},
		6px 0 4px 0 ${props => props.theme.neutrals.lightOne};

	ul {
		display: grid;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: 1.4; /** match with marketplace site */
		/* We put at max five icons in a row */
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

		li {
			position: relative;

			&:nth-of-type(5) {
				.sitenav__icon {
					svg {
						transform: translateY(-1px);
					}
				}
			}
			&.active {
				.sitenav__link {
					.sitenav__icon {
						position: relative;

						&:before {
							content: '';
							position: absolute;
							top: 12px;
							bottom: 17px;
							left: 14px;
							right: 14px;
							border-radius: 5px;
							background-color: #1da1f2;

							z-index: -1;
						}
						svg {
							fill: white;
							border-radius: 5px;
							path {
								&:first-of-type {
									fill: #1da1f2;
								}
								&:last-of-type {
									fill: white;
								}
							}
						}
					}
				}
			}
		}
		.sitenav__link {
			padding: 4px;
			text-decoration: none;
			color: ${props => props.theme.neutrals.secondaryText};
			display: flex;
			width: 100%;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			opacity: 0.75;
			transition: opacity 200ms ease-out;
			min-height: 64px;
			position: relative;

			&:hover {
				opacity: 1;
			}
			&.sitenav__link--home {
				opacity: 1;
			}
			&.sitenav__link--active {
				opacity: 1;
				font-weight: bold;
				background-color: #ecf2f3;
				color: #007bff;
			}
		}

		.sitenav__icon {
			margin-top: auto;

			.sitenav__largeicon {
				height: 28px;
				width: 28px;
				margin: 2px auto;
				display: block;
			}
		}

		.sitenav__hosting {
			height: 42px;
			width: 42px;
			margin-top: auto;

			.sitenav__largeicon {
				height: 28px;
				width: 28px;
				margin: 2px auto;
				display: block;
			}
		}

		.sitenav__label {
			flex-basis: 100%;
			text-align: center;
			font-size: 13px;
			margin-bottom: auto;
		}
	}

	/** Fix it as sidebar on larger devices */
	@media ${props => props.theme.breakpoints.tabletQuery} {
		/* static position because from this width, it will managed by upper container */
		position: static;
		width: 110px;
		height: 100%; /* reset the height from 64px, grid will make sure it takes all space */
		min-height: 590px; /* height of nav bar with 5 items in it */
		box-shadow: 1px 0 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 6px 0 ${props => props.theme.neutrals.lightOne};
		transform: translateZ(0);

		ul {
			grid-template-columns: 1fr;
			position: sticky;
			top: 0;

			.sitenav__link {
				height: 118px;
				width: 100%;
			}

			.sitenav__icon {
				height: 64px;
				width: 64px;

				.sitenav__largeicon {
					height: 40px;
					width: 40px;
					margin: 10px auto;
				}
			}

			.sitenav__hosting {
				height: 50px;
				width: 50px;

				.sitenav__largeicon {
					height: 40px;
					width: 40px;
					margin: 10px auto;
				}
			}

			.sitenav__label {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
`;

const NavUlWrap = styled.div`
	@media ${props => props.theme.breakpoints.tabletQuery} {
		/* we want the menu to be stuck to top */
		position: fixed;
		top: 0;
		width: 110px;
		height: 100%;
	}
`;

export const iconMaps = {
	hostjane: <HostJaneIcon className="sitenav__hosting" />,
	google: <GoogleIcon className="sitenav__hosting icon_active" />,
	aws: <AwsIcon className="sitenav__hosting icon_active" />,
	ibm: <IbmIcon className="sitenav__hosting icon_active" />,
	ovh: <OvhIcon className="sitenav__hosting icon_active" />,
	cloud: <JaneCloudIcon className="sitenav__hosting" />,
	vps: <JaneVPSIcon className="sitenav__hosting" />,
	wpaws: <JaneDediIcon className="sitenav__hosting" />,
	reseller: <JaneSellsIcon className="sitenav__hosting" />,
	accountIcon: (
		<span className="sitenav__icon">
			<AccountIcon className="sitenav__largeicon" />
		</span>
	),
	moreIcon: (
		<span className="sitenav__icon">
			<MoreIcon className="sitenav__largeicon" />
		</span>
	),
	categoriesIcon: (
		<span className="sitenav__icon">
			<CategoriesIcon className="sitenav__largeicon" />
		</span>
	),
	messagesIcon: (
		<span className="sitenav__icon">
			<MessagesIcon className="sitenav__largeicon" />
		</span>
	),
	skillsSidebarIcon: (
		<span className="sitenav__icon">
			<SkillsSidebarIcon className="sitenav__largeicon" />
		</span>
	),
	webHostingIcon: (
		<span className="sitenav__icon">
			<WebHostingIcon className="sitenav__largeicon" />
		</span>
	),
	webHostingIcon: (
		<span className="sitenav__icon">
			<WebHostingIcon className="sitenav__largeicon" />
		</span>
	),
	Wantcpanel: (
		<span className="sitenav__icon">
			<Wantcpanel className="sitenav__largeicon" />
		</span>
	),
};

const NAV_QUERY = graphql`
	query NavQuery {
		site {
			siteMetadata {
				navMainLink
				navMainLinkLabel
				navMainAltLink
				navMainAltLinkLabel
				nav {
					path
					icon
					label
					altLabel
					disableLink
					modal
					internal
				}
				mainSidebarNav {
					link
					internal
					icon
					label
					disableLink
					modal
				}
			}
		}
	}
`;

export function HomePageSideNav() {}

export default function SiteNav() {
	const data = useStaticQuery(NAV_QUERY);
	const location = useLocation();
	const [enableModal, setEnableModal] = useState(false);
	let wizardMainLink = data.site.siteMetadata.navMainLink;
	let wizardMainLabel = data.site.siteMetadata.navMainLinkLabel;

	if (
		(location.pathname &&
			location.pathname !== '/cloud/' &&
			location.pathname !== '/cloud') ||
		(location.pathname &&
			location.pathname !== '/wordpress/' &&
			location.pathname !== '/wordpress') ||
		(location.pathname &&
			location.pathname !== '/vps/' &&
			location.pathname !== '/vps') ||
		(location.pathname &&
			location.pathname !== '/windows/' &&
			location.pathname !== '/windows')
		(location.pathname &&
			location.pathname !== '/vps-hosting/' &&
			location.pathname !== '/vps-hosting')
	) {
		wizardMainLink = data.site.siteMetadata.navMainAltLink;
		wizardMainLabel = data.site.siteMetadata.navMainAltLinkLabel;
	}
	const enableListModal = isModal => {
		return isModal && setEnableModal(!enableModal);
	};

	return (
		<Nav id="hostjane-nav" className="sitenav">
			<NavUlWrap>
				<ul>
					{(location.pathname && location.pathname.includes('/wordpress/')) ||
					 (location.pathname && location.pathname.includes('/wordpress')) ||
				
					(location.pathname && location.pathname.includes('/cloud/')) ||
					(location.pathname && location.pathname.includes('/cloud')) ||

					(location.pathname && location.pathname.includes('/vps-hosting/')) ||
					(location.pathname && location.pathname.includes('/vps-hosting')) ||

					(location.pathname && location.pathname.includes('/vps/')) ||
						(location.pathname && location.pathname.includes('/vps')) ||
					
					(location.pathname && location.pathname.includes('/windows/')) ||
                    (location.pathname && location.pathname.includes('/windows/')) ? (
						<>
							<li
							// onClick={() => enableListModal(navItem?.modal)}
							>
								<Link
									to={wizardMainLink}
									className="sitenav__link sitenav__link--home"
									activeClassName="sitenav__link--active"
								>
									{iconMaps.hostjane}
									<span className="sitenav__label">
										{wizardMainLabel}
									</span>
								</Link>
							</li>
							{data.site.siteMetadata.nav.map(navItem => (
								<li
									key={navItem.path}
									onClick={() =>
										enableListModal(navItem.modal)
									}
									className={
										enableModal && navItem.modal
											? 'active'
											: undefined
									}
								>
									<Link
										to={navItem.path}
										className="sitenav__link"
										activeClassName="sitenav__link--active"
										onClick={e =>
											navItem.disableLink &&
											e.preventDefault()
										}
									>
										{iconMaps[navItem.icon]}
										<span className="sitenav__label">
											{navItem.label}
										</span>
									</Link>
									{navItem.modal && (
										<ListModal enableModal={enableModal} />
									)}
								</li>
							))}
						</>
					) : (
						<>
							{data.site.siteMetadata.mainSidebarNav.map(
								navItem => (
									<li
										onClick={() =>
											enableListModal(navItem.modal)
										}
										key={navItem.link}
										className={
											enableModal && navItem.modal
												? 'active'
												: undefined
										}
									>
										{navItem.internal ? (
											<Link
												to={navItem.link}
												className="sitenav__link"
												activeClassName="sitenav__link--active"
												onClick={e =>
													navItem.disableLink &&
													e.preventDefault()
												}
											>
												{iconMaps[navItem.icon]}
												<span className="sitenav__label">
													{navItem.label}
												</span>
											</Link>
										) : (
											<a
												href={navItem.link}
												className="sitenav__link"
												onClick={e =>
													navItem.disableLink &&
													e.preventDefault()
												}
											>
												{iconMaps[navItem.icon]}
												<span className="sitenav__label">
													{navItem.label}
												</span>
											</a>
										)}
										{navItem.modal && (
											<ListModal
												enableModal={enableModal}
											/>
										)}
									</li>
								)
							)}
						</>
					)}
				</ul>
			</NavUlWrap>
		</Nav>
	);
}
